import React, { useContext, useState } from 'react';

const MenuContext = React.createContext();

export function MenuContextProvider({ children }) {
  const [menuState, setMenuState] = useState([]);

  return <MenuContext.Provider value={[menuState, setMenuState]}>{children}</MenuContext.Provider>;
}

export function useMenu() {
  return useContext(MenuContext);
}
