import './Sidebar.css';
import { Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react';

import {useMenu} from '../Context/Context';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
function Menu({menus, open}){

    const [menuState, setMenuState] = useMenu();

    const [active, setActive] = useState({title: "Statisztikák", icon: ""});

    const style = {
        width: open ? '250px' : '50px',
    }

    const styleText = {
        width: open ? 'auto' : '0px',
    }

    useEffect(() => {
        setMenuState(active)
    }, [active])

    return(
        
            menus.map(({title, icon:Icon, type, path}) => {
                if(type === "seperator"){
                    return(
                        <div style={style} className={'seperator'}>
                        </div>
                    )
                }
                else{
                    return(
                        <Link to={path}>
                            <div onClick={() => setActive({title: title, icon: Icon})} style={style} className={'menu' + (active.title === title ? ' active' : '')}>
                                <Grid container>
                                    <Grid item xs={open ? 3 : 12}>
                                        <div className={'icon'}>
                                            <FontAwesomeIcon icon={Icon} />
                                        </div>
                                    </Grid>
                                    <Grid style={styleText} item xs={open ? 9 : 0}>
                                        <div className={'text'}>
                                            {title}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Link>
                    )
                }
            })
    )
}

export default Menu;