import { Grid } from '@material-ui/core';
import {useState} from 'react';
import './App.css';
import Content from './components/Content/Content';
import Header from './components/Header/Header';
import Loader from './components/Loader/Loader';
import Sidebar from './components/Sidebar/Sidebar';
function App() {
  const [loading, setLoading] = useState(false);


  return (
    <div>
      <Header/>
      
          <Sidebar/>

          <Content/>

      
      
    </div>
  );
}

export default App;
