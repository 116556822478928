import { Grid } from "@material-ui/core";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

function SidebarHeader({title, open}){

    const styleText = {
        width: open ? 'auto' : '0px',
    }

    const style = {
        width: open ? '250px' : '80px',

    }

    return (
        <div style={style} className={'header'}>
            <Grid container>
                <Grid item xs={open ? 3 : 12}>
                    <div className={'short'}>
                        <LocalShippingIcon/>
                    </div>
                </Grid>
                <Grid style={styleText} item xs={open ? 9 : 0}>
                    <div className={'text'}>
                        Varga Trans
                    </div>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default SidebarHeader;