import './Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChartLine } from '@fortawesome/free-solid-svg-icons'
import SearchIcon from '@material-ui/icons/Search';
import { useEffect, useState } from 'react'
import { useMenu } from '../Context/Context';

function Header(){

    const [menuState, setMenuState] = useMenu();

    const [toggle, setToggle] = useState(false);

    const buttonStyle = {
        transform: toggle ? 'rotate(180deg)' : '', 
        transition: 'transform 150ms ease',
        fontSize: '12px',
    }
    const modalStyle = {
        visibility: toggle ? 'visible' : 'hidden',
        opacity: toggle ? '1' : '0',
        transition: 'visibility 0s, opacity 0.5s linear'
    }

    return(
        <div>
            <div className={'headerBar'}>
                <div className={'left'}>
                    <div className={'pageTitle'}>
                    <FontAwesomeIcon style={{marginRight: "10px"}} icon={menuState.icon} /> {menuState.title}
                    </div>
                </div>
                
                <div className={'right'}>
                    <div className={'searchIcon'}>
                        <SearchIcon/>
                    </div>
                    <input placeholder={'Keresés'} className={'input'}></input>

                    <div onClick={() => setToggle(!toggle)} className={'headerText'}>
                    <span>Dániel <FontAwesomeIcon style={buttonStyle} icon={faChevronDown} /></span>
                </div>
                </div>

                
            </div>
            <div style={modalStyle} className={'userMenu'}>
                <li>Profilom</li>
                <li>Beállítások</li>
                <li>Kijelentkezés</li>
            </div>
        </div>
    )
}

export default Header;