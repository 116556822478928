import { useState } from "react";
import Loader from '../../Loader/Loader'

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button } from "@material-ui/core";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEye } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
});

function createData(id, type, plate, km) {
    return { id, type, plate, km };
}

const rows = [
    createData('#VRG-001', "Renault Boxer", "AAA-111", 24214),
    createData('#VRG-002', "Fiat Ducato", "AAA-112", 372131),
    createData('#VRG-003', "Ford Transit", "AAA-113", 245123),
    createData('#VRG-004', "Fiat Ducato", "AAA-114", 675512),
    createData('#VRG-005', "Citroen Jumper", "AAA-115", 4921),
];

function Cars(){

    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    return(
        <div className={'content'}>
            <div style={{display: loading ? "block" : "none"}}>
                <Loader />
            </div>

            <div style={{textAlign: "right"}}>
                <Button variant="contained" color="primary" style={{marginBottom: "10px"}}>
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </div>
            

            <div className={'item'}>
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Típus</TableCell>
                            <TableCell>Rendszám</TableCell>
                            <TableCell>Km óra állás</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                                {row.id}
                            </TableCell>
                            <TableCell>{row.type}</TableCell>
                            <TableCell>{row.plate}</TableCell>
                            <TableCell>{row.km} km</TableCell>
                            <TableCell align="right">
                                <Button variant="contained" color="primary">
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>


        </div>
    )
}

export default Cars;