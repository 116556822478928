import { CircularProgress } from "@material-ui/core";
import { useState } from 'react';

function Loader(){

    const style = {
        position: "absolute",
        left: "50%",
        bottom: "20px",
        transform: "translateX: -50%"
    }

    return(
        <CircularProgress style={style} />
    )
}

export default Loader;