import { useState } from 'react';
import Statistics from './Statistics/Statistics';
import Cars from './Cars/Cars';
import { faChartLine, faFileAlt, faGasPump, faTruckLoading, faUsers, faTruck, faMoneyCheck, faCoins, faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import {useMenu} from '../Context/Context';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

function Content(){

    const [menuState, setMenuState] = useMenu();

    const [menu, setMenu] = useState([
        {
            title: "Járművek",
            icon: faTruck,
            path: "/jarmuvek",
            component: Cars
        },
        {
            title: "Munkák",
            icon: faTruckLoading,
            path: "/munkak",
            component: ""
        },
        {
            title: "Tankolások",
            icon: faGasPump,
            path: "/tankolasok",
            component: ""
        },
        {
            title: "Sofőrök",
            icon: faUsers,
            path: "/soforok",
            component: ""
        },
        {
            title: "Dokumentumok",
            icon: faFolderOpen,
            path: "/dokumentumok",
            component: ""
        },
        {
            title: "Elszámolások",
            icon: faMoneyCheck,
            path: "/elszamolasok",
            component: ""
        },
        {
            title: "Pénzügy",
            icon: faCoins,
            path: "/penzugy",
            component: ""
        },
        {
            title: "Napilap",
            icon: faFileAlt,
            path: "/napilap",
            component: ""
        },
        {
            title: "Statisztikák",
            icon: faChartLine,
            path: "/",
            component: Statistics
        }
    ]);

    return(
        menu.map(item => {
            return(
                <Route exact path={item.path} component={item.component}>
                </Route>
            )
        })
    )
}

export default Content;