import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Chart from './Chart';
import './Statistics.css';

import Loader from '../../Loader/Loader'

function Item({title, number}){
    return(
        <Grid item xs={12} md={3}>
            <div className={'item'}>
                <div className={'text'}>
                    <div className={'dashboard'}>
                        <div className={'number'}>
                            {number}
                        </div>
                        <div className={'title'}>
                            {title}
                        </div>
                    </div>
                </div>
            </div>
        </Grid>
    )
}

function Statistics(){

    const [loading, setLoading] = useState(true);

    const [stats, setStats] = useState([
        {
            title: 'Elvégzett munkák',
            number: 312
        },
        {
            title: 'Folyamatban lévő munkák',
            number: 122
        },
        {
            title: 'Sofőrök',
            number: 23
        },
        {
            title: 'Nyereség',
            number: '13.231.000 Ft'
        },
        
    ]);

    useEffect(() => {
        setLoading(false)
    })

    

    return(
        <div className={'content'}>
            <div style={{display: loading ? "block" : "none"}}>
                <Loader />
            </div>

            <Grid container spacing={2}>
                {stats.map(stat => {
                    return <Item key={stat.title} title={stat.title} number={stat.number}/>
                })}

                <Grid item xs={12} md={4}>
                    <div className={'item'}>
                        <div className={'text'}>
                            <Chart/>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div className={'item'}>
                        <div className={'text'}>
                            <Chart/>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div className={'item'}>
                        <div className={'text'}>
                            <Chart/>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Statistics;