import './Sidebar.css';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { useState } from 'react';
import Menu from './Menu';

import { faChartLine, faFileAlt, faGasPump, faTruckLoading, faUsers, faTruck, faMoneyCheck, faCoins, faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import SidebarHeader from './SidebarHeader';
import { Router } from "react-router-dom";

function Sidebar(){
    const [toggle, setToggle] = useState(false);

    const [menu, setMenu] = useState([
        {
            title: "Statisztikák",
            icon: faChartLine,
            path: "/"
        },
        {
            title: "Járművek",
            icon: faTruck,
            path: "/jarmuvek"
        },
        {
            title: "Munkák",
            icon: faTruckLoading,
            path: "/"
        },
        {
            title: "Tankolások",
            icon: faGasPump,
            path: "/"
        },
        {
            title: "Sofőrök",
            icon: faUsers,
            path: "/"
        },
        {
            type: "seperator"
        },
        {
            title: "Dokumentumok",
            icon: faFolderOpen,
            path: "/"
        },
        {
            title: "Elszámolások",
            icon: faMoneyCheck,
            path: "/"
        },
        {
            title: "Pénzügy",
            icon: faCoins,
            path: "/"
        },
        {
            title: "Napilap",
            icon: faFileAlt,
            path: "/"
        },
    ]);

    const buttonStyle = {
        transform: toggle ? 'rotate(180deg)' : '', 
        transition: 'transform 150ms ease'
    }
    const sidebarStyle = {
        width: toggle ? '250px' : '60px',
        transition: 'width 150ms ease'
    }

    return(
        <div style={sidebarStyle} className={'sidebar'}>
            <SidebarHeader title={'Admin'} open={toggle}/>
                <Menu menus={menu} open={toggle}></Menu>
            <div style={buttonStyle} className={'toggleButton'} onClick={() => setToggle(!toggle)}><ArrowForward/></div>
        </div>
    )
}

export default Sidebar;